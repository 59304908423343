<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/BerandaNgumahLogo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div id="myModal" class="modal">
        <span class="close">&times;</span>
        <img class="modal-content" id="img01">
        <div id="caption"></div>
    </div>
    <div class="container-fluid testimonial bg-breadcrumb py-2 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container text-center py-2">
            &nbsp;
        </div>
    </div>
    <div class="container-fluid bg-breadcrumbberanda wow fadeInDown">
        <Carousel :wrap-around="true" :breakpoints="breakpoints" :autoplay="5000">
            <Slide v-for="slide in slides" :key="slide.id">
                <div class="carousel__item"
                    :style="'background:linear-gradient(0deg, rgba(60, 60, 60, 0.5), rgba(60, 60, 60, 0.5)), url(' + slide.url + ');background-position: center;background-repeat: no-repeat;background-size: cover;background-color: rgba(223, 12, 12, 0);'">
                    <h1 class="col-sm-12 carousel__h1" style="font-style: normal;">{{ slide.title }}</h1>
                    <p class="col-sm-12 text-center"><span v-html="slide.content"></span></p>
                </div>
            </Slide>
            <template #addons>
                <Navigation />
                <Pagination />
            </template>
        </Carousel>
    </div>
    <!-- Header End -->

    <div class="container-fluid py-3" v-for="(beranda, urutberanda) in databeranda" :key="urutberanda">
        <div class="container py-3">
            <div class="row g-5 align-items-center" v-if="urutberanda % 2 == 0">
                <div class="col-lg-5 wow fadeInLeft" data-wow-delay="0.4s" v-if="beranda.ekstensi != 'MP4'">
                    <img :src="folder_foto + beranda.filenya_sekilas"
                        class="img-fluid rounded-circle w-100 h-100 gambar" :id="beranda.id"
                        @click="ambilgambar(beranda.id)" :alt="beranda.nama">
                </div>
                <div class="col-lg-5 wow fadeInLeft" data-wow-delay="0.4s" v-else>
                    <video style="height:35vh" controls id="framevideo">
                        <source :src="folder_foto + beranda.filenya_sekilas" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div class="col-lg-7 wow fadeInRight" data-wow-delay="0.4s">
                    <div class="section-title text-start">
                        <h4 class="sub-title pe-3 mb-0 pmistitelberita">
                            <span v-if="(bahasa === null) || (bahasa == 'ina')">{{ beranda.nama }}</span>
                            <span v-else>{{ beranda.nama_eng }}</span>
                        </h4>
                        <br><br>
                        <p class="mb-4">
                            <span v-if="(bahasa === null) || (bahasa == 'ina')"><span
                                    v-html="beranda.konten"></span></span>
                            <span v-else><span v-html="beranda.konten_eng"></span></span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="row g-5 align-items-center" v-else>
                <div class="col-lg-7 wow fadeInLeft" data-wow-delay="0.4s">
                    <div class="section-title text-start">
                        <h4 class="sub-title pe-3 mb-0 pmistitelberita">
                            <span v-if="(bahasa === null) || (bahasa == 'ina')">{{ beranda.nama }}</span>
                            <span v-else>{{ beranda.nama_eng }}</span>
                        </h4>
                        <br><br>
                        <p class="mb-4">
                            <span v-if="(bahasa === null) || (bahasa == 'ina')"><span
                                    v-html="beranda.konten"></span></span>
                            <span v-else><span v-html="beranda.konten_eng"></span></span>
                        </p>
                    </div>
                </div>
                <div class="col-lg-5 wow fadeInRight" data-wow-delay="0.4s" v-if="beranda.ekstensi != 'MP4'">
                    <img :src="folder_foto + beranda.filenya_sekilas"
                        class="img-fluid rounded-circle w-100 h-100 gambar" :id="beranda.id"
                        @click="ambilgambar(beranda.id)" :alt="beranda.nama" style="cursor: pointer;">
                </div>
                <div class="col-lg-5 wow fadeInRight" data-wow-delay="0.4s" v-else>
                    <video style="height:35vh" controls id="framevideo">
                        <source :src="folder_foto + beranda.filenya_sekilas" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    </div>
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal, Carousel, Slide, Pagination, Navigation,
    },
    data() {
        return {
            halamanloading: true,
            tabaktif: encodeURIComponent(CryptoJS.AES.encrypt('', 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString()),
            datapesan: '',
            datamaster: [],
            tahun: new Date().getFullYear(),
            slides: [],
            breakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 1,
                    snapAlign: 'start',
                },
            },
            bahasa: localStorage.getItem("bahasasistem"),
            databeranda: [],
            folder_foto: process.env.VUE_APP_URL_API + 'parastapainnovation_sekilas-Gambar?f=',
        };
    },
    methods: {
        ambilgambar(id) {
            var modal = document.getElementById("myModal");
            // Get the image and insert it inside the modal - use its "alt" text as a caption
            var img = document.getElementById(id);
            var modalImg = document.getElementById("img01");
            var captionText = document.getElementById("caption");
            modal.style.display = "block";
            modalImg.src = img.src;
            captionText.innerHTML = img.alt;

            // Get the <span> element that closes the modal
            var span = document.getElementsByClassName("close")[0];

            // When the user clicks on <span> (x), close the modal
            span.onclick = function () {
                modal.style.display = "none";
            }
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation-GetSlider?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.slides = [];
                        this.datamaster = Response.data.content.data;
                        if ((this.bahasa == null) || (this.bahasa == 'ina')) {
                            this.datamaster.forEach((item) => {
                                this.slides.push({
                                    id: item.id,
                                    title: item.nama,
                                    content: atob(item.konten),
                                    url: process.env.VUE_APP_URL_API + 'parastapainnovation_slider-Gambar?f=' + item.filenya_slider,
                                });
                            });
                        }
                        else {
                            this.datamaster.forEach((item) => {
                                this.slides.push({
                                    id: item.id,
                                    title: item.nama_eng,
                                    content: atob(item.konten_eng),
                                    url: process.env.VUE_APP_URL_API + 'parastapainnovation_slider-Gambar?f=' + item.filenya_slider,
                                });
                            });
                        }
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamaster = [];
                this.slides = [];
                console.log(error);
                this.halamanloading = false;
            });

            this.halamanloading = true;
            var random = Math.random();
            await mainAPI.get("parastapainnovation-GetInfoBeranda?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        var datasementara = [];
                        this.databeranda = [];
                        datasementara = Response.data.content.data;
                        datasementara.forEach((item) => {
                            this.databeranda.push({
                                id: item.id,
                                nama: item.nama,
                                nama_eng: item.nama_eng,
                                konten: atob(item.konten),
                                konten_eng: atob(item.konten_eng),
                                filenya_sekilas: item.filenya_sekilas,
                                ekstensi: item.ekstensi,
                            });
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamaster = [];
                this.slides = [];
                console.log(error);
                this.halamanloading = false;
            });

            this.halamanloading = true;
            const data = {
                tamuparastapa: 'tamuparastapa'
            };
            const mainAPIVisitor = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });
            await mainAPIVisitor.post("parastapainnovatioVisitor-PullVisitor", data).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: Response.data.message,
                        });
                        this.halamanloading = false;
                        return false;
                    }
                    else {
                        this.halamanloading = false;
                    }
                }
            ).catch(
                error => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: error,
                    });
                }
            )
        },
    },
    mounted() {
        this.ambildata();

    },
}
</script>

<style>
.carousel__prev,
.carousel__next {
    box-sizing: content-box;
    border: 5px solid #0055aa;
    border-radius: 40px;
    background-color: #0055aa;
    color: aliceblue;
    height: 50px;
    width: 50px;
}
</style>